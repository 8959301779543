<template>
	<section class="bg-color-white rounded-lg shadow-xl px-6 mb-4">
		<div class="flex justify-between pt-8 mb-10">
			<h4 id="experiences-headline" class="inline-block mb-0">Berufserfahrung</h4>
			<HokButton
				v-if="!viewOnly"
				is-text
				color="main"
				font-weight="light"
				@click="addNewExperience(false)"
				>+ Hinzufügen</HokButton
			>
		</div>
		<div
			v-if="internalShowGaps && distanceBetweenLastExpAndTodayInMonths > 6 && !hasRecentEducation"
			class="pb-5 mb-5 border-b border-color-grey-lightest text-center"
		>
			<div class="bg-color-main rounded-lg py-6 px-4 relative" :class="{ 'mx-20': !$isMobile.any }">
				<HokIcon
					color="white"
					name="icon:close-slim"
					:size="4"
					pointer
					class="absolute top-3 right-4"
					@click="internalShowGaps = false"
				/>
				<div class="flex space-x-3 text-start">
					<div>
						<div class="bg-color-white bg-opacity-30 rounded-full p-3">
							<HokIcon color="white" name="icon:hand" />
						</div>
					</div>
					<div>
						<h4 class="text-color-white mb-2 text-sm">Lücke im Lebenslauf?</h4>
						<p class="text-color-white text-sm">
							Du hast seit mehr als 6 Monaten keine Erfahrung eingetragen.
						</p>
					</div>
				</div>
				<HokButton
					color="main"
					size="small"
					class="border border-color-white"
					@click="fillExperienceGap"
				>
					Ergänzen</HokButton
				>
			</div>
		</div>
		<template v-if="experienceList && experienceList.length">
			<template v-if="showMore">
				<CvExperienceElementEditMode
					v-for="(experience, index) in experienceList"
					:key="experience._id"
					:experience="experience"
					:previous-exp="calculatePreviousExp(index)"
					:show-gap="gapConditionsArray[index]"
					:view-only="viewOnly"
					:show-incomplete-warning="showIncompleteWarning"
					class="mb-8 pb-6 border-b border-color-grey-lightest"
					:class="{ 'pb-1': experience && experience.summary }"
					@edit-experience="$emit('edit-experience')"
				/>
			</template>
			<template v-else>
				<CvExperienceElementEditMode
					v-for="(experience, index) in experienceList.slice(0, 3)"
					:key="experience._id"
					:experience="experience"
					:previous-exp="calculatePreviousExp(index)"
					:show-gap="gapConditionsArray[index]"
					:view-only="viewOnly"
					:show-incomplete-warning="showIncompleteWarning"
					class="mb-8 border-b border-color-grey-lightest"
					:class="[
						{ 'last:border-none last:pb-12 last:mb-0': experienceList.length <= 3 },
						experience && experience.summary ? 'pb-1' : 'pb-6'
					]"
					@edit-experience="$emit('edit-experience')"
				/>
			</template>

			<div
				v-if="experienceList && experienceList.length > 3"
				class="w-full flex flex-col justify-center items-center cursor-pointer"
				@click="toggleShowMore"
			>
				<p v-if="!showMore" class="mb-1">Mehr anzeigen</p>
				<p v-else class="mb-1">Weniger anzeigen</p>
				<HokIcon
					ref="arrow-down"
					name="icon:arrow-down"
					:class="{ 'rotate-180 ': showMore }"
					class="transition-transform mb-6 duration-3000 bg-transparent"
				/>
			</div>
		</template>
		<p v-else class="-mt-6 pb-10 text-color-grey mb-4-medium">
			Erzähle mehr über deine Berufserfahrung.
		</p>
	</section>
</template>

<script lang="ts">
import { diff } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/diff';
import type { IAPILoginUser, IAPIUserCvEducation, IAPIUserCvExperience } from '@hokify/common';
/*
import { EducationStatus } from '@hokify/common';
*/
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import { mapStores } from 'pinia';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { useUserProfileStore } from '@/stores/user-profile';
import CvExperienceElementEditMode from './CvExperienceElementEditMode.vue';

function hasEndDate(obj: any): obj is { endDate: string | number } {
	return obj && typeof obj === 'object' && obj.endDate !== undefined;
}

enum EducationStatus {
	Completed = 'completed',
	Canceled = 'canceled',
	Studying = 'studying'
}

export default defineComponent({
	components: { CvExperienceElementEditMode },
	emits: ['edit-experience'],
	data() {
		const previousExp = undefined as undefined | IAPIUserCvExperience;

		return {
			previousExp,
			showMore: false,
			internalShowGaps: this.showGaps,
			EventBus
		};
	},
	beforeUnmount() {
		this.EventBus.$off('fill-experience-gap');
		this.EventBus.$off('deleted');
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		educationList(): IAPIUserCvEducation[] {
			return this.userProfileStore.obj?.cvInfo?.educations || [];
		},
		experienceList(): IAPIUserCvExperience[] {
			return this.userProfileStore.obj?.cvInfo?.experiences || [];
		},
		lastExperience() {
			return this.experienceList[0];
		},
		lastEdu() {
			return this.educationList[0];
		},
		distanceBetweenLastExpAndTodayInMonths() {
			if (this.lastExperience?.isCurrent) {
				return 0;
			}
			if (this.lastExperience?.endDate) {
				return diff(this.lastExperience.endDate, 'months', new Date());
			}
			return 0;
		},
		distanceBetweenLastEduAndTodayInMonths() {
			if (this.lastEdu?.status && this.lastEdu?.status === EducationStatus.Studying) {
				return 0;
			}
			if (this.lastEdu?.endDate) {
				return diff(this.lastEdu.endDate, 'months', new Date());
			}
			return 0;
		},
		hasRecentEducation() {
			// if there is an current education
			if (
				this.educationList[0]?.status &&
				this.educationList[0]?.status === EducationStatus.Studying
			) {
				return true;
			}

			// only if education endDate is in the past
			if (
				this.educationList[0]?.endDate &&
				new Date(this.educationList[0].endDate).getTime() < new Date().getTime()
			) {
				// if the last education is not older than 6 months return true
				// if last education is older than 6 months return false
				return diff(this.educationList[0].endDate, 'months', new Date()) < 6;
			}
			return false;
		},
		gapConditionsArray(): boolean[] {
			let previousExp = undefined as undefined | IAPIUserCvExperience;
			const gapsArr: boolean[] = [];
			if (this.experienceList?.length) {
				this.experienceList.forEach(experience => {
					if (previousExp) {
						const expDifference = experience?.endDate
							? diff(experience?.endDate, 'months', previousExp?.startDate) > 6
							: false;
						let eduGap;

						if (this.educationList) {
							let prevExpGap;
							let followingExpGap;
							this.educationList.forEach(education => {
								if (education?.endDate && previousExp?.startDate) {
									prevExpGap = diff(education?.endDate, 'months', previousExp?.startDate) > 6;
								}
								if (education?.startDate) {
									followingExpGap = experience?.endDate
										? diff(experience?.endDate, 'months', education?.startDate) > 6
										: false;
								}
								eduGap = prevExpGap || followingExpGap;
							});
						}
						previousExp = experience;
						gapsArr.push(expDifference && eduGap);
						return gapsArr;
					}
					previousExp = experience;
					gapsArr.push(false);
					return gapsArr;
				});
			}
			return gapsArr;
		},
		...mapStores(useUserProfileStore)
	},
	methods: {
		calculatePreviousExp(index: number) {
			const previousExperience =
				index >= 1 ? this.experienceList[index - 1] : this.experienceList[0];
			if (previousExperience.startDate) {
				return new Date(previousExperience.startDate);
			}
			return undefined;
		},
		async addNewExperience(allowToFillGap = false, allowNoExperience = true) {
			const experienceComponent = markRaw(
				defineAsyncComponent(() => import('../quickCv/quickExperience.vue'))
			);
			try {
				await this.$page.push(
					experienceComponent,
					{
						profileMode: true,
						allowToFillGap,
						allowNoExperience,
						editExperience: false
					},
					{
						pageTitle: 'Berufserfahrung hinzufügen',
						name: 'add-experience',
						done: () => {
							this.EventBus.$off('fill-experience-gap');
						}
					}
				);
				this.EventBus.$on('fill-experience-gap', async () => {
					await this.$page.goBack();
					await this.fillExperienceGap();
				});
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async fillExperienceGap() {
			const myEndDate = hasEndDate(this.lastExperience) && this.lastExperience.endDate;
			let useDate;
			if (myEndDate) {
				useDate = new Date(myEndDate);
			}
			const experienceComponent = markRaw(
				defineAsyncComponent(() => import('./ExperienceGap.vue'))
			);
			try {
				await this.$page.push(
					experienceComponent,
					{
						fullDate: true,
						isOnboarding: false,
						lastExpEndDate: useDate,
						editExperience: false
					},
					{
						pageTitle: 'Beschreibe deine Tätigkeit',
						name: 'fill-experience-gap'
					}
				);
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		toggleShowMore() {
			this.showMore = !this.showMore;
		}
	},
	props: {
		styling: { type: String, default: 'user' },
		showGaps: { type: Boolean, default: true },
		viewOnly: { type: Boolean, default: false },
		showIncompleteWarning: { type: Boolean, default: true }
	}
});
</script>

<style src="@hokify/pwa-core-nuxt3/assets/styles/hokbox.scss" lang="scss" scoped></style>
