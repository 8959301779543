<template>
	<!--	editMode-->
	<div>
		<p
			class="flex mb-3 font-bold"
			:class="`${education.school && education.school.name ? 'justify-between' : 'justify-end'}`"
		>
			<span v-if="education.school && education.school.name">{{ education.school.name }}</span>
			<HokIcon
				v-if="!viewOnly"
				name="icon:pencil"
				:size="5"
				pointer
				color="main"
				class="inline ml-1"
				vertical-align="align-text-top"
				@click.stop="editEducation(education)"
			/>
		</p>
		<p
			v-if="!education.isVisible && showIncompleteWarning"
			class="flex justify-between text-color-purple mb-1"
		>
			Eingaben unvollständig!
		</p>
		<div class="flex">
			<div class="w-2/3">
				<p
					v-if="education.startDate && education.status && education.status === 'studying'"
					class="mb-0"
				>
					{{ formatDateMMyyyy(education.startDate) }} - Heute
				</p>
				<p v-else-if="education.startDate && education.endDate" class="mb-0">
					{{ formatDateMMyyyy(education.startDate) }} -
					{{ formatDateMMyyyy(education.endDate) }}
				</p>
				<p v-if="education.level && education.level.value" class="mb-0">
					{{ education.level.value }}
					{{
						education.sublevel && education.sublevel.value ? ' -  ' + education.sublevel.value : ''
					}}
				</p>
			</div>
			<div v-if="hasMoreThanOneEdu && !viewOnly && education.isVisible" class="w-1/3 text-right">
				<HokIcon
					v-if="education.selectedAsHighlight"
					:size="5"
					color="yellow"
					vertical-align="align-baseline"
					class="inline"
					name="icon:star"
					pointer
					@click.stop="starEducation(education)"
				/>
				<HokIcon
					v-else
					:size="5"
					color="yellow"
					vertical-align="align-baseline"
					class="inline"
					pointer
					name="icon:star-outline"
					@click.stop="starEducation(education)"
				/>
			</div>
		</div>

		<p
			v-if="
				education.degree &&
				education.degree.value &&
				education.status &&
				education.status === 'completed'
			"
			class="mb-0"
		>
			{{ education.degree.value }}
		</p>
	</div>
</template>

<script lang="ts">
import { formatDateMMyyyy } from '@hokify/pwa-core-nuxt3/helpers/date';
import type { IAPILoginUser, IAPIUserCvEducation } from '@hokify/common';
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import type { PropType } from 'vue';
import { mapStores } from 'pinia';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { useUserProfileEducationStore } from '@/stores/user-profile-education';
import { useUserProfileStore } from '@/stores/user-profile';

export default defineComponent({
	data() {
		return {
			formatDateMMyyyy,
			EventBus
		};
	},
	beforeUnmount() {
		this.EventBus.$off('end-go-to-edu');
		this.EventBus.$off('deleted');
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		hasMoreThanOneEdu(): boolean {
			return !!(
				this.userProfileStore.obj?.cvInfo?.educations?.length &&
				this.userProfileStore.obj.cvInfo.educations.length > 1
			);
		},
		...mapStores(useUserProfileEducationStore, useUserProfileStore)
	},
	methods: {
		async editEducation(education) {
			const educationComponent = markRaw(
				defineAsyncComponent(() => import('../quickCv/quickEducation.vue'))
			);
			try {
				await this.$page.push(
					educationComponent,
					{
						editEducation: education,
						profileMode: true
					},
					{
						pageTitle: 'Ausbildung bearbeiten',
						name: 'edit-education',
						done: () => {
							this.EventBus.$off('end-go-to-edu');
							this.EventBus.$off('deleted');
						}
					}
				);
				this.EventBus.$on('end-go-to-edu', () => this.$page.goBack());
				this.EventBus.$on('deleted', edu => this.confirmDeleted(edu));
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async starEducation(education) {
			if (!education.selectedAsHighlight) {
				try {
					await this.userProfileEducationStore.highlightEducation({
						educationId: education._id,
						highlight: true
					});
					this.$snack.success({
						text: 'Auswahl wird in Bewerbung hervorgehoben.'
					});
				} catch (err: any) {
					return this.$nuxt.$errorHandler(
						err,
						'Es ist ein Fehler beim Hervorheben deiner Ausbildung aufgetreten. Bitte versuch es später noch einmal.'
					);
				}
			} else {
				try {
					await this.userProfileEducationStore.highlightEducation({
						educationId: education._id,
						highlight: false
					});
					this.$snack.success({
						text: 'Auswahl wird nicht mehr hervorgehoben.'
					});
				} catch (err: any) {
					return this.$nuxt.$errorHandler(
						err,
						'Es ist ein Fehler beim Entfernen deiner hervorgehobenen Ausbildung aufgetreten. Bitte versuch es später noch einmal.'
					);
				}
			}
		},
		confirmDeleted(edu) {
			this.$page.goBack();
			this.$snack.success({
				text: `${edu} wurde erfolgreich gelöscht.`
			});
		}
	},
	props: {
		education: { type: Object as PropType<IAPIUserCvEducation>, default: undefined },
		showIncompleteWarning: { type: Boolean, default: true },
		viewOnly: { type: Boolean, required: true, default: false }
	}
});
</script>
