<template>
	<!--	editMode experience -->
	<div
		v-if="
			(experience.fields && experience.fields.length > 0) ||
			(experience.source === 'cv-parsing' && !experience.abstention)
		"
	>
		<div
			v-if="internalShowGap && !viewOnly"
			class="pb-5 mb-5 border-b border-color-grey-lightest text-center"
		>
			<div class="bg-color-main rounded-lg py-6 px-4 relative" :class="{ 'mx-20': !$isMobile.any }">
				<HokIcon
					color="white"
					name="icon:close-slim"
					:size="4"
					pointer
					class="absolute top-3 right-4"
					@click="internalShowGap = false"
				/>
				<div class="flex space-x-3 text-start">
					<div>
						<div class="bg-color-white bg-opacity-30 rounded-full p-3">
							<HokIcon color="white" name="icon:hand" />
						</div>
					</div>
					<div>
						<h4 class="text-color-white mb-2 text-sm">Lücke im Lebenslauf?</h4>
						<p class="text-color-white text-sm">
							Ergänze jetzt deine Erfahrung<br />
							oder Infos über deine Auszeit.
						</p>
					</div>
				</div>
				<HokButton
					color="main"
					size="small"
					class="border border-color-white"
					@click="fillExperienceGap(experience.endDate, previousExp)"
				>
					Ergänzen</HokButton
				>
			</div>
		</div>
		<p
			class="flex mb-3 font-bold"
			:class="`${experience.title ? 'justify-between' : 'justify-end'}`"
		>
			<span v-if="experience.title">{{ experience.title }}</span>
			<HokIcon
				v-if="!viewOnly"
				name="icon:pencil"
				:size="5"
				pointer
				color="main"
				class="inline ml-1"
				vertical-align="align-text-top"
				@click.stop="editExperience(experience)"
			/>
		</p>
		<p
			v-if="!experience.isVisible && showIncompleteWarning && !experience.abstention"
			class="flex justify-between text-color-purple mb-1"
		>
			Eingaben unvollständig!
		</p>
		<div class="flex">
			<div class="w-2/3">
				<p v-if="experience.startDate && experience.isCurrent" class="mb-0">
					{{ formatDateMMyyyy(experience.startDate) }} - Heute
				</p>
				<p v-else-if="experience.startDate && experience.endDate" class="mb-0">
					{{ formatDateMMyyyy(experience.startDate) }} -
					{{ formatDateMMyyyy(experience.endDate) }}
				</p>
				<p
					v-if="experience.company && experience.company.name"
					:class="{ 'mb-0': !experience.summary }"
				>
					{{ experience.company.name }}
				</p>
			</div>
			<div v-if="hasMoreThanOneExp && !viewOnly" class="w-1/3 text-right">
				<HokIcon
					v-if="experience.selectedAsHighlight"
					:size="5"
					color="yellow"
					vertical-align="align-baseline"
					class="inline"
					name="icon:star"
					pointer
					@click.stop="starExperience(experience)"
				/>
				<HokIcon
					v-else
					:size="5"
					color="yellow"
					vertical-align="align-baseline"
					class="inline"
					pointer
					name="icon:star-outline"
					@click.stop="starExperience(experience)"
				/>
			</div>
		</div>

		<div v-if="experience.summary" class="text-color-grey w-5/6">
			<BeautifyText :text="experience.summary" />
		</div>
	</div>
	<!--	editMode experience gap -->
	<div v-else class="flex flex-wrap mb-2 sm:mb-6 pb-2" @click="editExperienceGap(experience)">
		<div class="w-2/3">
			<p>
				<span v-if="experience.startDate && experience.isCurrent">
					{{ formatDateMMyyyy(experience.startDate) }} - Heute
				</span>
				<span v-else-if="experience.startDate && experience.endDate">
					{{ formatDateMMyyyy(experience.startDate) }} - {{ formatDateMMyyyy(experience.endDate) }}
				</span>
			</p>
			<div v-if="experience.summary" class="text-color-grey">
				<BeautifyText :text="experience.summary" />
			</div>
		</div>
		<div class="w-1/3 text-right">
			<HokIcon
				v-if="!viewOnly"
				name="icon:pencil"
				:size="5"
				pointer
				color="main"
				class="inline ml-1"
				vertical-align="align-text-top"
				@click.stop="editExperienceGap(experience)"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import { formatDateMMyyyy } from '@hokify/pwa-core-nuxt3/helpers/date';
import BeautifyText from '@hokify/shared-components-nuxt3/lib/components/BeautifyText.vue';
import type { IAPILoginUser, IAPIUserCvExperience } from '@hokify/common';
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import type { PropType } from 'vue';
import { mapStores } from 'pinia';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { useUserProfileExperienceStore } from '@/stores/user-profile-experience';
import { useUserProfileStore } from '@/stores/user-profile';

export default defineComponent({
	name: 'CvExperienceElementEditMode',
	components: {
		BeautifyText
	},
	data() {
		return {
			formatDateMMyyyy,
			internalShowGap: this.showGap,
			EventBus
		};
	},
	beforeUnmount() {
		this.EventBus.$off('end-go-to-exp');
		this.EventBus.$off('fill-experience-gap');
		this.EventBus.$off('deleted');
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		hasMoreThanOneExp(): boolean {
			return !!(
				this.userProfileStore.obj?.cvInfo?.experiences?.length &&
				this.userProfileStore.obj.cvInfo.experiences.length > 1
			);
		},
		...mapStores(useUserProfileExperienceStore, useUserProfileStore)
	},
	methods: {
		async addNewExperience(experienceStart, experienceEnd) {
			const experienceComponent = markRaw(
				defineAsyncComponent(() => import('../quickCv/quickExperience.vue'))
			);
			try {
				await this.$page.push(
					experienceComponent,
					{
						profileMode: true,
						fullDate: true,
						isOnboarding: false,
						allowToFillGap: true,
						allowNoExperience: true
					},
					{
						pageTitle: 'Berufserfahrung hinzufügen',
						name: 'add-experience',
						done: () => {
							this.EventBus.$off('end-go-to-exp');
							this.EventBus.$off('fill-experience-gap');
						}
					}
				);
				this.EventBus.$on('end-go-to-exp', () => this.$page.goBack());
				this.EventBus.$on('fill-experience-gap', async () => {
					await this.$page.goBack();
					await this.fillExperienceGap(experienceStart, experienceEnd);
				});
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async fillExperienceGap(experienceStart, experienceEnd) {
			const experienceComponent = markRaw(
				defineAsyncComponent(() => import('./ExperienceGap.vue'))
			);
			try {
				await this.$page.push(
					experienceComponent,
					{
						fullDate: true,
						isOnboarding: false,
						generalGapStart: new Date(experienceStart),
						generalGapEnd: new Date(experienceEnd)
					},
					{
						pageTitle: 'Beschreibe deine Tätigkeit',
						name: 'fill-experience-gap',
						done: () => {
							this.EventBus.$off('end-go-to-exp');
						}
					}
				);
				this.EventBus.$on('end-go-to-exp', () => this.gapFilled());
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async editExperience(experience) {
			const experienceComponent = markRaw(
				defineAsyncComponent(() => import('../quickCv/quickExperience.vue'))
			);
			try {
				await this.$page.push(
					experienceComponent,
					{
						editExperience: experience, // clone it
						profileMode: true
					},
					{
						pageTitle: 'Berufserfahrung bearbeiten',
						name: 'edit-experience',
						done: () => {
							this.EventBus.$off('end-go-to-exp');
							this.EventBus.$off('deleted');
						}
					}
				);
				this.EventBus.$on('end-go-to-exp', () => this.$page.goBack());
				this.EventBus.$on('deleted', exp => this.confirmDeleted(exp));
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async editExperienceGap(experience) {
			const experienceComponent = markRaw(
				defineAsyncComponent(() => import('./ExperienceGap.vue'))
			);
			try {
				await this.$page.push(
					experienceComponent,
					{
						editExperience: experience, // clone it
						fullDate: true,
						isOnboarding: false
					},
					{
						pageTitle: 'Tätigkeit bearbeiten',
						name: 'edit-experience',
						done: () => {
							this.EventBus.$off('end-go-to-exp');
							this.EventBus.$off('deleted');
						}
					}
				);
				this.EventBus.$on('end-go-to-exp', () => this.$page.goBack());
				this.EventBus.$on('deleted', exp => this.confirmDeleted(exp));
			} catch (err) {
				return this.$nuxt.$errorHandler(err);
			}
		},
		async starExperience(experience) {
			if (!experience.selectedAsHighlight) {
				try {
					await this.userProfileExperienceStore.highlightExperience({
						experienceId: experience._id,
						highlight: true
					});
					this.$snack.success({
						text: 'Auswahl wird in Bewerbung hervorgehoben.'
					});
				} catch (err: any) {
					return this.$nuxt.$errorHandler(
						err,
						'Es ist ein Fehler beim Hervorheben deiner Erfahrung aufgetreten. Bitte versuch es später noch einmal.'
					);
				}
			} else {
				try {
					await this.userProfileExperienceStore.highlightExperience({
						experienceId: experience._id,
						highlight: false
					});
					this.$snack.success({
						text: 'Auswahl wird nicht mehr hervorgehoben.'
					});
				} catch (err: any) {
					return this.$nuxt.$errorHandler(
						err,
						'Es ist ein Fehler beim Entfernen deiner hervorgehobenen Erfahrung aufgetreten. Bitte versuch es später noch einmal.'
					);
				}
			}
		},
		confirmDeleted(exp) {
			this.$page.goBack();
			this.$snack.success({
				text: `${exp || 'Tätigkeit'} wurde erfolgreich gelöscht.`
			});
		},
		gapFilled() {
			this.internalShowGap = false;
			this.$page.goBack();
		}
	},
	props: {
		experience: { type: Object as PropType<IAPIUserCvExperience>, default: undefined },
		showIncompleteWarning: { type: Boolean, default: true },
		showGap: { type: Boolean, default: false },
		previousExp: { type: Date, required: false, default: () => {} },
		viewOnly: { type: Boolean, required: true, default: false }
	}
});
</script>
