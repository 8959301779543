<template>
	<section class="bg-color-white rounded-lg shadow-xl px-6 mb-4">
		<div class="flex justify-between pt-8 mb-10">
			<h4 id="educations-headline" class="inline-block mb-0">Ausbildung</h4>
			<HokButton
				v-if="!viewOnly"
				is-text
				color="main"
				font-weight="light"
				@click="addNewEducation()"
				>+ Hinzufügen</HokButton
			>
		</div>
		<template v-if="educationList && educationList.length">
			<template v-if="showMore">
				<CvEducationElementEditMode
					v-for="education in educationList"
					:key="education._id"
					:education="education"
					:user="user"
					:view-only="viewOnly"
					:show-incomplete-warning="showIncompleteWarning"
					class="mb-8 pb-6 border-b border-color-grey-lightest"
					@edit-education="$emit('edit-education')"
				/>
			</template>
			<template v-else>
				<CvEducationElementEditMode
					v-for="education in educationList.slice(0, 3)"
					:key="education._id"
					:education="education"
					:user="user"
					:view-only="viewOnly"
					:show-incomplete-warning="showIncompleteWarning"
					class="mb-8 pb-6 border-b border-color-grey-lightest"
					:class="{ 'last:border-none last:pb-12 last:mb-0': educationList.length <= 3 }"
				/>
			</template>

			<div
				v-if="educationList && educationList.length > 3"
				class="w-full flex flex-col justify-center items-center cursor-pointer"
				@click="toggleShowMore"
			>
				<p v-if="!showMore" class="mb-1">Mehr anzeigen</p>
				<p v-else class="mb-1">Weniger anzeigen</p>
				<HokIcon
					ref="arrow-down"
					name="icon:arrow-down"
					:class="{ 'rotate-180 ': showMore }"
					class="transition-transform mb-6 duration-3000 bg-transparent"
				/>
			</div>
		</template>
		<p v-else class="-mt-6 pb-10 text-color-grey-medium mb-4">
			Erzähle mehr über deine Ausbildung.
		</p>
	</section>
</template>

<script lang="ts">
import type { IAPILoginUser, IAPIUserCvEducation } from '@hokify/common';

import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import { mapStores } from 'pinia';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { useUserProfileStore } from '@/stores/user-profile';
import CvEducationElementEditMode from './CvEducationElementEditMode.vue';

export default defineComponent({
	components: { CvEducationElementEditMode },
	emits: ['edit-education'],
	data() {
		return {
			showMore: false,
			EventBus
		};
	},
	beforeUnmount() {
		this.EventBus.$off('end-go-to-edu');
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		educationList(): IAPIUserCvEducation[] {
			return this.userProfileStore.obj?.cvInfo?.educations || [];
		},
		...mapStores(useUserProfileStore)
	},
	methods: {
		async addNewEducation() {
			const educationComponent = markRaw(
				defineAsyncComponent(() => import('../quickCv/quickEducation.vue'))
			);
			try {
				await this.$page.push(
					educationComponent,
					{
						profileMode: true
					},
					{
						pageTitle: 'Ausbildung hinzufügen',
						name: 'add-education',
						done: () => {
							this.EventBus.$off('end-go-to-edu');
						}
					}
				);
				this.EventBus.$on('end-go-to-edu', () => this.$page.goBack());
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		toggleShowMore() {
			this.showMore = !this.showMore;
		}
	},
	props: {
		styling: { type: String, default: 'user' },
		showGaps: { type: Boolean, default: true },
		showIncompleteWarning: { type: Boolean, default: true },
		viewOnly: { type: Boolean, default: false }
	}
});
</script>

<style src="@hokify/pwa-core-nuxt3/assets/styles/hokbox.scss" lang="scss" scoped></style>
